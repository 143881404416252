














































import { computed, defineComponent } from '@vue/composition-api';
import { useDemandStore } from './store';
import { useApi } from '@/util/useApi';
import { getDemands, Demand } from './api';
import VClamp from 'vue-clamp';

export default defineComponent({
  components: { VClamp },
  setup(_, { root }) {
    const { state } = useDemandStore();

    const { result: demand, refresh } = useApi(() => getDemands({ page: state.page, size: 15 }));

    return {
      page: computed(() => state.page),
      total: computed(() => (demand.value ? demand.value.total : 0)),
      demands: computed(() => (demand.value ? demand.value.data : [])),
      toDetail(item: Demand) {
        root.$router.push({ name: 'DemandDetail', params: { id: item.no } });
      },
      onPageChange: (page: number) => {
        state.page = page;
        refresh();
      },
    };
  },
});
